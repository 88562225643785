import React from 'react';
import { TabPanel } from '../../../../common/tabpanel/TabPanel';
import { TabProps } from './types';
import { useParams } from 'react-router-dom';
import { useAppSelector } from '../../../../common/hooks/useAppSelector';
import { selectAllDocuments } from '../../../../slices/documents/documentsSlice';
import { useTranslation } from 'react-i18next';
import { selectUser } from '../../../../slices/user/userSlice';

export const DocumentConnectionsTab = ({ currentTab }: TabProps) => {
  const { documentId } = useParams();
  const document = useAppSelector(selectAllDocuments).find((el) => el.id === Number(documentId));
  const { t } = useTranslation();
  const currentUser = useAppSelector(selectUser);
  return (
    <TabPanel value={currentTab} index={3} prefix={'vertical'}>
      <>
        <div className='p-4'>
          <h2 className='mb-4'>{t('documentTabs.connections')}</h2>
        </div>
        <div className='d-flex p-4 pt-0'>
          <div className='col-3 pe-3 text-capitalize fw-bold'>{document?.category}:</div>
          <div className='col-9'>{currentUser.email}</div>
        </div>
      </>
    </TabPanel>
  );
};
