import React, { useEffect, useState } from 'react';
import { Grid } from '@mui/material';
import { SettingsMenu } from './components/SettingsMenu';
import { useAppDispatch } from '../../common/hooks/useAppDispatch';
import { fetchDocuments, selectAllDocuments } from '../../slices/documents/documentsSlice';
import { useTranslation } from 'react-i18next';
import { useParams, useSearchParams, useLocation, useNavigate } from 'react-router-dom';
import { DocumentContentTab } from './components/tabs/DocumentContentTab';
import { DocumentSummaryTab } from './components/tabs/DocumentSummaryTab';
import { DocumentVersionsTab } from './components/tabs/DocumentVersionsTab';
import { DocumentConnectionsTab } from './components/tabs/DocumentConnectionsTab';
import { ProgressOverlay } from '../../common/progress-overlay/ProgressOverlay';
import { NWClient } from '../../client/NWClient';
import { AIAssistModel } from '../../models/ai.assist.model';
import { DocumentVaultItemModel } from '../../models/document.vault.item.model';
import { toast } from 'react-toastify';
import { DocumentHtmlModel } from '../../models/document.html.model';

const DocumentItemPage = () => {
  const { documentId } = useParams();
  const [params] = useSearchParams();
  const tab = params.get('initialTab');
  const navigate = useNavigate();
  const location = useLocation();
  const path = location.pathname;
  const { t } = useTranslation();
  const token = localStorage.getItem('access_token');
  const dispatch = useAppDispatch();
  const [loading, setLoading] = useState<boolean>(true);
  const [summary, setSummary] = useState('');
  const [documentHTML, setDocumentHTML] = useState('');
  const [documentItem, setDocumentItem] = useState<DocumentVaultItemModel | null>(null);
  const [currentTab, setCurrentTab] = useState(tab ? Number(tab) : 0);
  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setCurrentTab(newValue);
    navigate(`${path}?initialTab=${newValue}`);
  };

  useEffect(() => {
    dispatch(fetchDocuments(token));
    NWClient.get(token, 'document-upload', documentId).then((res: DocumentVaultItemModel) => {
      setDocumentItem(res);
    });
  }, []);

  useEffect(() => {
    if (documentItem) {
      NWClient.get(
        token,
        'ai-assist',
        documentItem.open_ai_assist_document_upload[
          documentItem.open_ai_assist_document_upload.length - 1
        ]
      )
        .then((res: AIAssistModel) => {
          setSummary(res.content);
          NWClient.get(
            token,
            'document-upload-html',
            documentItem.document_upload_html[documentItem.document_upload_html.length - 1]
          )
            .then((res: DocumentHtmlModel) => {
              setDocumentHTML(res.html);
            })
            .catch((error) => {
              if (error.response && error.response.status !== 404) {
                toast.error(error.message || t('messages.errorOccurred'), { theme: 'colored' });
              }
            })
            .finally(() => setLoading(false));
        })
        .catch((error) => {
          if (error.response && error.response.status !== 404) {
            toast.error(error.message || t('messages.errorOccurred'), { theme: 'colored' });
          } else {
            NWClient.get(
              token,
              'document-upload-html',
              documentItem.document_upload_html[documentItem.document_upload_html.length - 1]
            )
              .then((res: DocumentHtmlModel) => {
                setDocumentHTML(res.html);
              })
              .catch((error) => {
                if (error.response && error.response.status !== 404) {
                  toast.error(error.message || t('messages.errorOccurred'), { theme: 'colored' });
                }
              })
              .finally(() => setLoading(false));
          }
        });
    }
  }, [documentItem]);

  useEffect(() => {
    if (tab) {
      setCurrentTab(Number(tab));
    }
  }, [tab]);

  return (
    <>
      <h1 className='mb-4'>{`${
        documentItem ? documentItem.name : t('documentPage.headerDefault')
      }`}</h1>
      <Grid container spacing={4}>
        <Grid item xs={12} md={3}>
          <SettingsMenu value={currentTab} handleChange={handleChange} />
        </Grid>
        <Grid item xs={12} md={9}>
          <div className='setting-panel mb-5'>
            {currentTab === 0 && <DocumentSummaryTab currentTab={currentTab} summary={summary} />}
            {currentTab === 1 && (
              <DocumentContentTab
                currentTab={currentTab}
                documentName={documentItem ? documentItem.name : ''}
                htmlContent={documentHTML}
              />
            )}

            {currentTab === 2 && (
              <DocumentVersionsTab
                documentName={documentItem ? documentItem.name : ''}
                currentTab={currentTab}
              />
            )}
            <DocumentConnectionsTab currentTab={currentTab} />
          </div>
        </Grid>
      </Grid>
      {loading ? <ProgressOverlay /> : null}
    </>
  );
};
export default DocumentItemPage;
